export const CREATE_PERSONA = "CREATE_PERSONA";

export const CREATE_PERSONA_EP = "CREATE_PERSONA_EP";

export const CREATE_DIRECCION = "CREATE_DIRECCION";

export const RETRIEVE_LOCALIDADES = "RETRIEVE_LOCALIDADES";

export const RETRIEVE_MUNICIPIOS = "RETRIEVE_MUNICIPIOS";

export const RETRIEVE_ENFERMEDADES = "RETRIEVE_ENFERMEDADES";
export const CREATE_ENFERMEDAD = "CREATE_ENFERMEDAD";
export const UPDATE_ENFERMEDAD = "UPDATE_ENFERMEDAD";
export const DELETE_ENFERMEDAD = "DELETE_ENFERMEDAD";

export const RETRIEVE_DIAGNOSTICOS_EP = "RETRIEVE_DIAGNOSTICOS_EP";
export const CREATE_DIAGNOSTICO = "CREATE_DIAGNOSTICO";
export const UPDATE_DIAGNOSTICO = "UPDATE_DIAGNOSTICO";
export const DELETE_DIAGNOSTICO = "DELETE_DIAGNOSTICO";

export const RETRIEVE_EVOLUCION_EP = "RETRIEVE_EVOLUCION_EP";
export const CREATE_EVOLUCION = "CREATE_EVOLUCION";
export const UPDATE_EVOLUCION = "UPDATE_EVOLUCION";
export const DELETE_EVOLUCION = "DELETE_EVOLUCION";

export const RETRIEVE_OBRASOCIAL = "RETRIEVE_OBRASOCIAL";
export const CREATE_OBRASOCIAL = "CREATE_OBRASOCIAL";
export const UPDATE_OBRASOCIAL = "UPDATE_OBRASOCIAL";
export const DELETE_OBRASOCIAL = "DELETE_OBRASOCIAL";

export const RETRIEVE_OS_EP = "RETRIEVE_OS_EP";
export const CREATE_OS = "CREATE_OS";
export const UPDATE_OS = "UPDATE_OS";
export const DELETE_OS = "DELETE_OS";

export const RETRIEVE_MEDICAMENTOS = "RETRIEVE_MEDICAMENTOS";
export const CREATE_MEDICAMENTO = "CREATE_MEDICAMENTO";
export const UPDATE_MEDICAMENTO = "UPDATE_MEDICAMENTO";
export const DELETE_MEDICAMENTO = "DELETE_MEDICAMENTO";

export const RETRIEVE_INDICACION_EP = "RETRIEVE_INDICACION_EP";
export const CREATE_INDICACION = "CREATE_INDICACION";
export const UPDATE_INDICACION = "UPDATE_INDICACION";
export const DELETE_INDICACION = "DELETE_INDICACION";

export const RETRIEVE_PACIENTES = "RETRIEVE_PACIENTES";

export const CAMBIAR = "CAMBIAR";